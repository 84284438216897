import { useAuth } from "@Ignite-Reading/ui-kit/auth";
import { Button, Tooltip } from "@Ignite-Reading/ui-kit/components";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import PresencePill, {
  JOINED,
  NOT_PRESENT,
  LEFT,
} from "components/PresencePill";
import SessionDetailsDialog from "components/SessionDetailsDialog";
import { generateStudentEditLink, generateStudentLATLink } from "utils";
import SessionService from "services/Session";

const KEEP_BUTTON_DISABLED_TIMEOUT = 3000;

const LiveSessionItem = ({
  admin,
  sessionId,
  student,
  studentStatus,
  studentUuid,
  tutor,
  tutorStatus,
  userSessionOverrideClaimed,
  uuid,
}) => {
  const disabledTimerRef = useRef(null);
  const toastIdRef = useRef(null);
  const [isJoiningSession, setIsJoiningSession] = useState(false);
  const { user } = useAuth();
  const user_uuid = user?.ignite_uuid;
  const tabId = `session-${user_uuid}`;

  const handleJoinClick = () => {
    toastIdRef.current = toast.loading("Hang tight! Joining session…");
    setIsJoiningSession(true);
    SessionService.handleJoinSession(sessionId, user_uuid)
      .then(response => {
        if (response.status !== 200) {
          throw new Error(`HTTP error: ${response.status}`);
        }
        return response.data;
      })
      .then(data => {
        if (data && data.client_url) {
          window.open(data.client_url, tabId);
          toast.dismiss(toastIdRef.current);
          disabledTimerRef.current = setTimeout(() => {
            setIsJoiningSession(false);
          }, KEEP_BUTTON_DISABLED_TIMEOUT);
        }
      })
      .catch(() => {
        setIsJoiningSession(false);
        toast.update(toastIdRef.current, {
          autoClose: 3200,
          isLoading: false,
          render: "Oops! Unable to join session.",
          type: "error",
        });
      });
  };

  useEffect(() => {
    return () => {
      clearTimeout(disabledTimerRef.current);
    };
  }, []);

  return (
    <div className="flex items-center justify-between py-3.5 px-5 space-x-4 w-full group-last:rounded-b-lg">
      <div className="space-y-0.5">
        <ul className="flex flex-wrap">
          {admin ? (
            <li className="p-1">
              <PresencePill
                data-testid="admin-pill"
                isSessionMonitor
                status={JOINED}>
                {admin}
              </PresencePill>
            </li>
          ) : null}
          <li className="p-1">
            <PresencePill
              data-testid="tutor-pill"
              status={tutorStatus}
              isSub={userSessionOverrideClaimed}>
              {tutor}
            </PresencePill>
          </li>
          <li className="p-1">
            <PresencePill
              data-testid="student-pill"
              hideStatusDot
              href={generateStudentEditLink(studentUuid)}
              status={studentStatus}>
              {student}
            </PresencePill>
          </li>
        </ul>
        <SessionDetailsDialog.Trigger
          disableJoinButton={isJoiningSession}
          handleJoinClick={handleJoinClick}
          showJoinSessionButton={true}
          title="Live session"
          uuid={uuid}
        />
      </div>
      <div>
        <div className="flex items-center">
          <Tooltip>
            <Tooltip.Trigger>
              <Button
                component="a"
                className="rounded-none rounded-l-lg -mr-[1px]"
                href={generateStudentLATLink(studentUuid)}
                data-testid="admin-lat-btn"
                target={`lesson-and-assessment-tracker-${tabId}`}
                variant={Button.SECONDARY}>
                Open LAT
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content
              className="text-xs"
              dark>
              Open Lesson and Assessment Tracker
            </Tooltip.Content>
          </Tooltip>
          <Button
            className="rounded-none rounded-r-lg"
            disabled={isJoiningSession}
            data-testid="admin-join-btn"
            onClick={handleJoinClick}
            variant={Button.SECONDARY}>
            Join Session
          </Button>
        </div>
      </div>
    </div>
  );
};

LiveSessionItem.propTypes = {
  admin: PropTypes.string,
  sessionId: PropTypes.number,
  student: PropTypes.string,
  studentStatus: PropTypes.oneOf([LEFT, NOT_PRESENT, JOINED]),
  studentUuid: PropTypes.string,
  tutor: PropTypes.string,
  tutorStatus: PropTypes.oneOf([LEFT, NOT_PRESENT, JOINED]),
  userSessionOverrideClaimed: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default LiveSessionItem;

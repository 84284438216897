import { addWeeks, formatISO, subWeeks } from "date-fns";

export { default as generateStudentEditLink } from "./generateStudentEditLink";
export { default as generateStudentLATLink } from "./generateStudentLATLink";

export function todayISO() {
  return formatISO(new Date(), { representation: "date" });
}

export function oneWeekFromTodayISO() {
  return formatISO(addWeeks(new Date(), 1), { representation: "date" });
}

export function oneWeekAgoFromTodayISO() {
  return formatISO(subWeeks(new Date(), 1), { representation: "date" });
}
